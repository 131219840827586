<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @downloadFile="onDownloadFile"
    @save="onSave('OneCPinCodes')"
    @cancel="onCancel('OneCPinCodes')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'OneCPinCodeEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'OneCPinCodes',
      pageHeader: 'Редактирование пин-кода 1с',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Пин-коды 1с',
          route: { name: 'OneCPinCodes' },
        },
        {
          text: 'Редактирование пин-кода 1с',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      servers: (state) => state.common.servers,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'regNumber',
              label: 'Рег.номер',
            },
            {
              type: 'text',
              key: 'type',
              label: 'Тип',
            },
            {
              type: 'select',
              key: 'serverId',
              label: 'Сервер',
              list: this.servers.data,
            },
            {
              type: 'text',
              key: 'currentPinCode',
              label: 'Текущий пин-код',
            },
            {
              type: 'text',
              key: 'unusedPinCodes',
              label: 'Не использованные пин-коды',
            },
            {
              type: 'file',
              key: 'licenseFile',
              label: 'Файл лицензии',
            },
            {
              type: 'file',
              key: 'licenseDataFile',
              label: 'Файл лицензионных данных',
            },
            {
              type: 'checkbox',
              key: 'isInstalled',
              label: 'Установлено',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const item = this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    })
    const servers = this.getCommonList({ name: 'Servers' });
    
    Promise.all([item, servers]).finally(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    validateForm(form, mandatoryFields) {
      const emptyField = mandatoryFields.filter((item) => {
        return !form[item.key];
      });
      let message = null;
      if (emptyField.length) {
        message = `Не заполнены обязательные поля: ${emptyField
          .map((item) => item.label)
          .join(', ')}`;
      }
      return message;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'regNumber', label: 'Рег.номер' }];

      const validationError = this.validateForm(this.form, mandatoryFields);
      if (validationError) {
        this.showNotification(validationError, 'error');
        return;
      }

      this.processSave(route);
    },

    showNotification(header, type) {
      this.$notify({
        header,
        type,
        timer: 5000,
      });
    },

    async processSave(route) {
      this.isLoadingPage = true;
      try {
        const result = await this.updateItem({
          name: this.controllerName,
          payload: this.form,
        });
        if (result.isSucceed) {
          this.$router.push({ name: route });
        }
      } finally {
        this.isLoadingPage = false;
      }
    },

    onDownloadFile({ key }) {
      const fileType = key === 'licenseDataFile' ? 'data' : 'file';
      
      this.downloadFile({
        name: this.controllerName,
        id: this.id,
        type: fileType,
      });
    },
  },
};
</script>
  